import React from 'react'
import BannerComponent from '../components/BannerComponent'
import Catalogue from '../components/Catalogue'
import OtherComponents from '../components/OtherComponents'

import Image from '../images/banner/technical-analysis.png'

const BannerContent = {
  title:"Technical Analysis",
  desc:"Fundamental and technical analyses are two different types of analysis that help investors understand price movements in the markets and predict future price movements.<br/><br/> A detailed technical analysis supported by 10 charts covering global markets, from Forex to Indices, Commodities to Stocks, ETFs to Cryptocurrencies and Currency Pairs, is shared in 7 different languages. These shares can be viewed instantly on investors' screens in the form of notifications. Technical analysis focuses on predicting future price movements by examining past price movements and trading volume.<br/><br/> In technical analysis, charts: <br/>• help predict price movements,<br/> • analyze trends,<br/> • identify support and resistance levels,<br/> • assist in identifying buy and sell signals.",
  image:Image,
}

const TechnicalAnalysis = () => {

  return (

    <>
      <BannerComponent data={BannerContent}/>
      <OtherComponents/>
      <Catalogue/>
    </>

  )

}

export default TechnicalAnalysis