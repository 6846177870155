import React from 'react'
import BannerComponent from '../components/BannerComponent'
import Catalogue from '../components/Catalogue'
import OtherComponents from '../components/OtherComponents'

import Image from '../images/banner/trading-volume.png'

const BannerContent = {
  title:"Trading Volume",
  desc:"The percentages of investment made on a product basis in global markets such as Forex, commodities, indices, stocks, cryptocurrencies, and currency pairs are displayed. Additionally, the trading direction of these products is shared as a percentage. <br/><br/>Through the Trading Volume system, investors gain insights into the trading volumes of financial assets. They can filter this information according to specific time intervals and have a clearer forecast about their investments.",
  image:Image,
}

const TradingVolume = () => {

  return (

    <>
      <BannerComponent data={BannerContent}/>
      <OtherComponents/>
      <Catalogue/>
    </>

  )

}

export default TradingVolume