import React from 'react'
import BannerComponent from '../components/BannerComponent'
import Catalogue from '../components/Catalogue'
import OtherComponents from '../components/OtherComponents'

import Image from '../images/banner/news-economic.png'

const BannerContent = {
  title:"News & Economic Calendar",
  desc:"Forex, index, commodities, stock ETFs, currency pairs, and cryptocurrencies are shared with 10 daily news articles in 7 different languages. The news includes announcements of:<br/><br/>• Economic indicators,<br/>• Central bank decisions,<br/>• Political developments,<br/>• International relations such as trade wars,<br/>• Geopolitical situations,<br/>• Natural disasters, and other significant events.<br/><br/> The dates and times of important events such as financial market events, data releases, central bank meetings, policy changes, and other significant events are provided within the economic calendar.",
  image:Image,
}

const NewsEconomicCalendar = () => {

  return (

    <>
      <BannerComponent data={BannerContent}/>
      <OtherComponents/>
      <Catalogue/>
    </>

  )

}

export default NewsEconomicCalendar