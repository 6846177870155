import React from 'react'
import BannerComponent from '../components/BannerComponent'
import Catalogue from '../components/Catalogue'
import OtherComponents from '../components/OtherComponents'

import Image from '../images/banner/signals-image.png'

const BannerContent = {
  title:"Signals",
  desc:"In many global products such as forex, indices, stocks, commodities, and cryptocurrencies, a minimum of 5 trading signals are shared daily.<br/><br/> On the homepage, the numerical results of the trading signals shared by the institution are displayed in the 'Trading Summary' section.",
  image:Image,
}

const Signals = () => {

  return (

    <>
      <BannerComponent data={BannerContent}/>
      <OtherComponents/>
      <Catalogue/>
    </>

  )

}

export default Signals