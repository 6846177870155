import React,{useState,useEffect} from 'react'

import Signals from '../images/signals.png'
import Technical from '../images/technical-fundamental.png'
import Trading from '../images/trading-volume.png'
import TechnicalSummary from '../images/technical-summaries.png'
import Deposit from '../images/deposit-withdraw.png'
import Educational from '../images/educational.png'
import News from '../images/news.png'
import Economic from '../images/economic.png'
import Special from '../images/special.png'
import Language from '../images/language.png'
import Gamification from '../images/gamification.png'
import Markets from '../images/markets.png'
import Notification from '../images/notifications.png'
import Feedback from '../images/feedback.png'
import IBSystem from '../images/ibsystem.png'

const components = [
    {
        image: Signals,
        title: "Signals",
        desc: "In global products such as forex, indices, stocks, commodities, and cryptocurrencies, we provide daily 10+ signal services",
        link: "/signals"
    },
    {
        image: Technical,
        title: "Technical&Fundamental Analysis",
        desc: "Daily minimum 10+ fundamental analysis and detailed technical analysis on global markets",
        link: "/technical-analysis"
        
    },
    {
        image: Trading,
        title: "Trading Volume",
        desc: "The ratio of Buy&Sell tradings occurring in the institution and indicators of product trading intensity",
        link: "/trading-volume"
    },
    {
        image: TechnicalSummary,
        title: "Technical Summaries",
        desc: "Summaries supported by technical indicators for financial assets",
        link: "/technical-sum"
    },
    {
        image: Deposit,
        title: "Deposit&Withdraw",
        desc: "A secure and fast deposit and withdrawal system with bank transfer, credit card, and cryptocurrency options",
        link: "/deposit-withdraw"
    },
    {
        image: Educational,
        title: "Educational Content",
        desc: "Over 30 technical and fundamental trainings created with videos and e-books",
        link: "/education"
    },
    {
        image: News,
        title: "News",
        desc: "Daily 10+ news in 7 different languages about Forex, indices, commodities, stocks, ETFs, currency pairs, and cryptos",
        link: "/news-economic-calendar"
    },
    {
        image: Economic,
        title: "Economic Calendar",
        desc: "Economic Calendar providing real-time updates on all developments in global markets",
        link: "/news-economic-calendar"
    },
    {
        image: Special,
        title: "Special for You",
        desc: "A system where individual campaigns are offered to each investor separately, and their tracking is personalized",
        link: "/special-for-you"
    },
    {
        image: Language,
        title: "Language Options",
        desc: "7 different language options including English, German, Spanish, French, Russian, Chinese, and Arabic",
        link: "/language-options"
    },
    {
        image: Gamification,
        title: "Gamification",
        desc: "Customized gamification systems that enable offering draws to investors on special occasions",
        link: "/gamification"
    },
    {
        image: Markets,
        title: "Markets",
        desc: "Data points displaying real-time price and exchange rate fluctuations of global market products",
        link: "/markets"
    },
    {
        image: Notification,
        title: "Notifications",
        desc: "A notification system that allows reaching investors via push notifications, SMS, and email on all kinds of matters",
        link: "/notifications"
    },
    {
        image: Feedback,
        title: "Feedback",
        desc: "A feedback point for consultants, applications, services, campaigns, and other topics",
        link: "#"
    },
    {
        image: IBSystem,
        title: "IB System",
        desc: "The IB System, which enables the tracking of investor data along with their acquisition and classification",
        link: "#"
    },
]

const AllComponents = () => {

    const [width, setWidth] = useState(window.innerWidth);
    const [opened, setOpened] = useState(true);

    useEffect(() => {
        const handleResize = () => {
        setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        console.log(opened)
    }, [opened]);

  return (
    <>
    {width > 1024 && 
    <div className='relative py-10'>
        <div className='text-center'>
          <p className='text-fitech-yellow font-bold'>Services</p>
          <p className='text-3xl font-bold pb-2'>All Components of AFA</p>
          <hr className='border-[1px] max-w-[280px] border-fitech-yellow mx-auto' />
        </div>

        {/* <div className='absolute right-0 top-16'>
            <button className='bg-fitech-yellow p-2 px-4 font-bold text-white rounded-full' onClick={()=>setOpened(!opened)}>
                {opened ? "🠗" : "🠕"}
            </button>
        </div> */}

        <div className='grid lg:grid-cols-4 sm:grid-cols-2 gap-8 py-8'>
        {
                opened ? components.map((c)=> {
                    return(
                        <a href={c.link}>
                            <div className='max-w-[256px] mx-auto text-center hover:shadow-lg p-4 rounded-lg'>
                                <img src={c.image} alt="" />
                                <p className='font-bold py-2'>{c.title}</p>
                                <p>{c.desc}</p>
                            </div>
                        </a>
                    )
                })
                :
                components.map((c)=> {
                    return(
                        <a href={c.link}>
                            <div className='max-w-[256px] mx-auto text-center hover:shadow-lg p-4 rounded-lg'>
                                <img src={c.image} alt="" />
                                <p className='font-bold py-2'>{c.title}</p>
                                <p>{c.desc}</p>
                            </div>
                        </a>
                    )
                })
            }
        </div>
    </div>
    }

    {/* MOBILE */}

    {width <= 1024 && 
    <div className='py-10'>
        <div className='text-center'>
          <p className='text-fitech-yellow font-bold'>Services</p>
          <p className='text-3xl font-bold pb-2'>All Components of AFA</p>
          <hr className='border-[1px] max-w-[280px] border-fitech-yellow mx-auto' />
        </div>

        <div className='grid lg:grid-cols-4 sm:grid-cols-2 gap-8 py-8'>
            {
                opened ? components.slice(0,4).map((c)=> {
                    return(
                        <a href={c.link}>
                            <div className='max-w-[256px] mx-auto text-center hover:shadow-lg p-4 rounded-lg'>
                                <img src={c.image} alt="" />
                                <p className='font-bold py-2'>{c.title}</p>
                                <p>{c.desc}</p>
                            </div>
                        </a>
                    )
                })
                :
                components.map((c)=> {
                    return(
                        <a href={c.link}>
                            <div className='max-w-[256px] mx-auto text-center hover:shadow-lg p-4 rounded-lg'>
                                <img src={c.image} alt="" />
                                <p className='font-bold py-2'>{c.title}</p>
                                <p>{c.desc}</p>
                            </div>
                        </a>
                    )
                })
            }
        </div>
        <div className='text-center'>
            <button className='bg-fitech-yellow p-2 font-bold text-white w-[250px]' onClick={()=>setOpened(!opened)}>
                {opened ? "View More" : "Close All Components"}
            </button>
        </div>
    </div>
    }
    </>
  )
}

export default AllComponents