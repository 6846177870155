import React from 'react'
import BannerComponent from '../components/BannerComponent'
import Catalogue from '../components/Catalogue'
import OtherComponents from '../components/OtherComponents'

import Image from '../images/banner/markets.png'

const BannerContent = {
  title:"Markets",
  desc:"Investors can track all products available in global markets and view price and price change rates instantly. Data changes for forex, commodities, indices, stocks, and cryptocurrencies are reflected in real-time on a category basis.<br/><br/>Investors can view details of products' prices, rises, and falls through the data stream band on the homepage. Data is reflected to investors in milliseconds in real-time.",
  image:Image,
}

const Markets = () => {

  return (

    <>
      <BannerComponent data={BannerContent}/>
      <OtherComponents/>
      <Catalogue/>
    </>

  )

}

export default Markets