import React from 'react'
import  Plan  from '../components/Plan'

import Banner from '../images/banner/home-banner.png'
import AppStore from '../images/icons/app-store.svg'
import GooglePlay from '../images/icons/google-play.svg'
import Image1 from '../images/homepage-image1.png'
import Icon1 from '../images/icons/global-access.svg'
import Icon2 from '../images/icons/investor-essentials.svg'
import Icon3 from '../images/icons/customizable-compatible.svg'
import AllComponents from '../components/AllComponents'
import Catalogue from '../components/Catalogue'

const Homepage = () => {

  return (
    <>
      <div className='h-[600px]' style={{ backgroundImage: `url(${Banner})` }}>
        <div className='grid lg:grid-cols-2 items-center h-[600px]'>
          <div className='text-center'>
            <p className='text-4xl'>Reshape the</p>
            <p className='text-4xl font-bold'>INVESTMENT WORLD</p>
            <p className='text-4xl'>with Advanced</p>
            <p className='text-4xl font-bold'>FiTechPro</p>
          </div>
          <div className='lg:block hidden'></div>
        </div>
        
      </div>
      <AllComponents/>
      <div className='py-8 lg:px-20'>
        <div className='text-center'>
          <p className='text-fitech-yellow font-bold'>Advantages</p>
          <p className='text-3xl font-bold pb-2'>UFIN’s General Features</p>
          <hr className='border-[1px] max-w-[280px] border-fitech-yellow mx-auto' />
        </div>

        <div className='grid lg:grid-cols-2 items-center'>
          <div className='mx-2 py-6'>
            <div className='flex gap-4 items-center pb-4'>
              <img src={Icon1} alt="" />
              <div>
                <p className='font-bold text-lg'>Global Access</p>
                <p>Easy access from global application stores and the web from all around the world. Support for 7 different languages, which can be expanded.</p>
              </div>
            </div>
            <div className='flex gap-4 items-center pb-4'>
              <img src={Icon2} alt="" />
              <div>
                <p className='font-bold text-lg'>Investor's Essentials</p>
                <p>From trade signals to analyses, technical indicators to news, educational content to draws and rewards, catering to all the specialized needs of investors.</p>
              </div>
            </div>
            <div className='flex gap-4 items-center pb-4 mb-4'>
              <img src={Icon3} alt="" />
              <div>
                <p className='font-bold text-lg'>Customizable & Compatible</p>
                <p>It is compatible with leading trading platforms such as MetaTrader, Panda, and C Trader, offering fully customizable content.</p>
              </div>
            </div>
            <div className='lg:flex items-center gap-4'>
              <div className='lg:block flex lg:justify-none justify-center'>
                <img src={AppStore} alt="" />
                <img src={GooglePlay} alt="" />
              </div>
              <p className='max-w-72 lg:mx-0 mx-auto lg:text-left text-center'>Supported by application stores such as Google Play Store and App Store.</p>
            </div>
          </div>
          <div>
            <img className='mx-auto' src={Image1} alt="" />
          </div>
        </div>  
      </div>
      <Plan />
      <Catalogue/>
    </>

  )

}

export default Homepage