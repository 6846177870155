import React from 'react'
import BannerComponent from '../components/BannerComponent'
import Catalogue from '../components/Catalogue'
import OtherComponents from '../components/OtherComponents'

import Image from '../images/banner/technical-sum.png'

const BannerContent = {
  title:"Technical Summaries",
  desc:"From the technical summary screen where investors can access more data on a product basis:<br/> •The main trend status of global products,<br/> • The current trading activity,<br/> • The distribution of up and down trading volumes can be displayed.<br/><br/> All this data is transferred to the technical summary screen on the application, which investors can access through data obtained from major providers.",
  image:Image,
}

const TechnicalSum = () => {

  return (

    <>
      <BannerComponent data={BannerContent}/>
      <OtherComponents/>
      <Catalogue/>
    </>

  )

}

export default TechnicalSum