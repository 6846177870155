import React from 'react'
import BannerComponent from '../components/BannerComponent'
import Catalogue from '../components/Catalogue'
import OtherComponents from '../components/OtherComponents'

import Image from '../images/banner/notifications.png'

const BannerContent = {
  title:"Notifications",
  desc:"Corporate notifications can be sent to investors through 3 different methods:<br/><br/><b>1. Application Notifications / Push Notification</b><br/> Signals, news, analyses, and all other announcements from the institution are shared with investors in the form of push notifications through the application. These announcements are instantly sent to the screens of investors as notifications when added to the application, ensuring real-time updates for all application users.<br/><br/><b>2. SMS</b> <br/>Notifications, special announcements, or campaign notifications are sent to investors via SMS. SMS notifications can be tailored for specific customer profiles or sent in bulk.<br/><br/> <b>3. E-mail</b><br/> Informative, special announcements, or campaign notifications containing visual details are sent to investors via email. Email notifications can be tailored for specific customer profiles or sent in bulk.",
  image:Image,
}

const Notification = () => {

  return (

    <>
      <BannerComponent data={BannerContent}/>
      <OtherComponents/>
      <Catalogue/>
    </>

  )

}

export default Notification