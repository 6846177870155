import React from 'react'
import BannerShape from '../images/banner/banner-shape.png'

const BannerComponent = ({data}) => {
  return (
    <>

        <div className='relative pt-3 lg:px-20'>
            <img className='absolute right-0 z-0' src={BannerShape} alt="" />
            <div className='grid lg:grid-cols-2 items-center lg:h-[540px] lg:mx-0 mx-4 p-5'>
                <div className='z-10 max-w-[600px]'>
                    <p className='text-3xl font-bold pb-4'>{data.title}</p>
                    <p dangerouslySetInnerHTML={{__html: data.desc}}></p>
                </div>
                <div className='z-10'>
                    {
                        data.image &&
                            <img className='mx-auto' src={data.image} alt="" />
                    }
                </div>
            </div>
        </div>
        
    </>
  )
}

export default BannerComponent