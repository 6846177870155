import React, { useState, useEffect, useRef } from 'react';
import Logo from '../images/icons/logo.svg';
import BurgerMenu from '../images/icons/burger-menu.svg';
import CloseBurgerMenu from '../images/icons/burger-closed.svg';
import Icon1 from '../images/icons/menu/home.svg';
import Icon2 from '../images/icons/menu/services.svg';
import Icon3 from '../images/icons/menu/advantages.svg';
// import Icon4 from '../images/icons/menu/prices.svg';
// import Icon5 from '../images/icons/menu/catalogue.svg';
import Icon6 from '../images/icons/menu/contact-us.svg';

const Navbar = () => {
  const [burger, setBurger] = useState('closed');
  const [servicesOpen, setServicesOpen] = useState(false);
  const [advantagesOpen, setAdvantagesOpen] = useState(false);
  const serviceRef = useRef(null);
  const advantagesRef = useRef(null);

  const toggleServices = () => {
    setServicesOpen(!servicesOpen);

    if (advantagesOpen) {
      setAdvantagesOpen(false);
    }

  };

  const toggleAdvantages = () => {
    setAdvantagesOpen(!advantagesOpen);

    if (servicesOpen) {
      setServicesOpen(false);
    }
  }
  

  const scrollToContactUs = () => {
    const contactUsSection = document.getElementById('footer');
    if (contactUsSection) {
      contactUsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const handleClickOutside = (event) => {
    
    if (serviceRef.current && !serviceRef.current.contains(event.target)) {
      setServicesOpen(false)
    }

    if (advantagesRef.current && !advantagesRef.current.contains(event.target)) {
      setAdvantagesOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav className="bg-white px-4 py-4 fixed w-full top-0 z-40 shadow-md">
        <div className="max-w-[1280px] mx-auto justify-between items-center z-40">
          <div className="flex text-black items-center z-40">
            <div className='flex-1'>
              <a href="/"><img className='inline-block pr-4 pb-1' src={Logo} alt="" /></a>
            </div>
            <div className='md:flex gap-8 float-right hidden'>
              <a href="/"><p className='p-2 hover:text-fitech-yellow'>Home</p></a>
              <div className="relative" ref={serviceRef}>
                <p className='p-2 hover:text-fitech-yellow cursor-pointer' onClick={toggleServices}>Services▾</p>
                {servicesOpen && (
                  <div className="absolute top-full left-0 bg-white shadow-lg py-2 px-4 w-48 mt-2 z-50" >
                    <a href="/signals"><p className='p-2 hover:text-fitech-yellow'>Signals</p></a>
                    <a href="/technical-analysis"><p className='p-2 hover:text-fitech-yellow'>Technical Analysis</p></a>
                    <a href="/technical-basic-analysis"><p className='p-2 hover:text-fitech-yellow'>Technical Basic Analysis</p></a>
                    <a href="/trading-volume"><p className='p-2 hover:text-fitech-yellow'>Trading Volume</p></a>
                    <a href="/technical-sum"><p className='p-2 hover:text-fitech-yellow'>Technical Sum</p></a>
                    <a href="/deposit-withdraw"><p className='p-2 hover:text-fitech-yellow'>Deposit & Withdraw</p></a>
                    <a href="/trading-volume"><p className='p-2 hover:text-fitech-yellow'>Trading Volume</p></a>
                    <a href="/education"><p className='p-2 hover:text-fitech-yellow'>Education</p></a>
                    <a href="/news-economic-calendar"><p className='p-2 hover:text-fitech-yellow'>News & Economic Calendar</p></a>
                    <a href="/language-options"><p className='p-2 hover:text-fitech-yellow'>Language Options</p></a>
                    <a href="/markets"><p className='p-2 hover:text-fitech-yellow'>Markets</p></a>

                  </div>
                )}
              </div>
              <div className="relative" ref={advantagesRef}>
                <p className='p-2 hover:text-fitech-yellow cursor-pointer' onClick={toggleAdvantages}>Advantages▾</p>
                {advantagesOpen && (
                  <div className="absolute top-full left-0 bg-white shadow-lg py-2 px-4 w-48 mt-2 z-50">
                    <a href="/special-for-you"><p className='p-2 hover:text-fitech-yellow'>Special For You</p></a>
                    <a href="/gamification"><p className='p-2 hover:text-fitech-yellow'>Gamification</p></a>
                    <a href="/notifications"><p className='p-2 hover:text-fitech-yellow'>Notifications</p></a>
                    <a href="/banner"><p className='p-2 hover:text-fitech-yellow'>Banner</p></a>
                    <a href="/desktop-interface"><p className='p-2 hover:text-fitech-yellow'>Desktop Interface</p></a>


                  </div>
                )}
              </div>
              {/* <a href="#"><p className='p-2 hover:text-fitech-yellow'>Prices</p></a> */}
              {/* <a href="#"><p className='p-2 hover:text-fitech-yellow'>Catalogue</p></a> */}
              <a href="#"><button onClick={scrollToContactUs} className='bg-fitech-yellow hover:bg-yellow-200 text-white p-2 px-3'><p>Contact Us</p></button></a>
            </div>
            <div className='flex float-right md:hidden block'>
              <button onClick={(e) => setBurger(burger === 'closed' ? 'opened' : 'closed')}>
                <img src={burger === 'closed' ? BurgerMenu : CloseBurgerMenu} alt="" />
              </button>
            </div>
          </div>
        </div>
      </nav>
      <div className={`bg-white w-full md:pt-0 pt-24 px-10 fixed font-semibold top-0 z-20 text-fitech-yellow overflow-hidden transition-all duration-500 ${burger === 'closed' ? 'h-0' : 'h-screen'}`}>
        <div className="md:hidden relative">
          <a href="/" className="block">
            <div className='flex'>
              <img src={Icon1} alt="" />
              <p className='p-3'>Home</p>
            </div>
          </a>
          <div className='relative'>
            <a href="#" onClick={toggleServices}>
              <div className='flex'>
                <img src={Icon2} alt="" />
                <p className='p-3'>Services▾</p>
              </div>
            </a>
            <div className={`submenu overflow-hidden transition-max-height duration-500 ${servicesOpen ? 'max-h-screen' : 'max-h-0'}`}>
              <div className="bg-white shadow-lg py-2 px-4 mt-2 z-50">
                <a href="/signals"><p className='p-2 hover:text-fitech-yellow'>Signals</p></a>
                <a href="/technical-analysis"><p className='p-2 hover:text-fitech-yellow'>Technical Analysis</p></a>
                <a href="/technical-basic-analysis"><p className='p-2 hover:text-fitech-yellow'>Technical Basic Analysis</p></a>
                <a href="/trading-volume"><p className='p-2 hover:text-fitech-yellow'>Trading Volume</p></a>
                <a href="/technical-sum"><p className='p-2 hover:text-fitech-yellow'>Technical Sum</p></a>
                <a href="/deposit-withdraw"><p className='p-2 hover:text-fitech-yellow'>Deposit & Withdraw</p></a>
                <a href="/trading-volume"><p className='p-2 hover:text-fitech-yellow'>Trading Volume</p></a>
                <a href="/education"><p className='p-2 hover:text-fitech-yellow'>Education</p></a>
                <a href="/news-economic-calendar"><p className='p-2 hover:text-fitech-yellow'>News & Economic Calendar</p></a>
                <a href="/language-options"><p className='p-2 hover:text-fitech-yellow'>Language Options</p></a>
                <a href="/markets"><p className='p-2 hover:text-fitech-yellow'>Markets</p></a>
              </div>
            </div>
          </div>
          <a href="#" className="block">
            <div className='relative'>
              <a href="#" onClick={toggleAdvantages}>
                <div className='flex'>
                  <img src={Icon3} alt="" />
                  <p className='p-3'>Advantages▾</p>
                </div>
              </a>
              <div className={`submenu overflow-hidden transition-max-height duration-500 ${advantagesOpen ? 'max-h-screen' : 'max-h-0'}`}>
                <div className="bg-white shadow-lg py-2 px-4 mt-2 z-50">
                  <a href="/special-for-you"><p className='p-2 hover:text-fitech-yellow'>Special For You</p></a>
                  <a href="/gamification"><p className='p-2 hover:text-fitech-yellow'>Gamification</p></a>
                  <a href="/notifications"><p className='p-2 hover:text-fitech-yellow'>Notifications</p></a>
                  <a href="/banner"><p className='p-2 hover:text-fitech-yellow'>Banner</p></a>
                  <a href="/desktop-interface"><p className='p-2 hover:text-fitech-yellow'>Desktop Interface</p></a>
                </div>
              </div>
            </div>
          </a>
          {/* <a href="#" className="block">
            <div className='flex'>
                <img src={Icon4} alt="" />
                <p className='p-3'>Prices</p>
            </div>
        </a> */}
          {/* <a href="#" className="block">
            <div className='flex'>
                <img src={Icon5} alt="" />
                <p className='p-3'>Catalogue</p>
            </div>
        </a> */}
          <button className="block" onClick={scrollToContactUs}>
            <div className='flex'>
              <img src={Icon6} alt="" />
              <p className='p-3'>Contact Us</p>
            </div>
          </button>
        </div>
      </div>

    </>
  );
};

export default Navbar;
