import React from 'react'
import BannerComponent from '../components/BannerComponent'
import Catalogue from '../components/Catalogue'
import OtherComponents from '../components/OtherComponents'

import Image from '../images/banner/banner.png'

const BannerContent = {
  title:"Banner",
  desc:"Uygulamanın ana sayfasında bulunan Banner’ a 5 farklı görsel eklenerek, yatırımcılara genel kurum bilgilendirmesi, uygulamaya dair  duyuru ve yenilikler, yatırımcılara dair kampanya ve çekilişler, uygulamaya eklenecek yeni özelliklere dair duyurular bu alandan yayınlanarak uygulamaya giriş yapan tüm üyelerin bu detayları görmesine imkan sağlanır.",
  image:Image,
}

const Banner = () => {

  return (

    <>
      <BannerComponent data={BannerContent}/>
      <OtherComponents/>
      <Catalogue/>
    </>

  )

}

export default Banner