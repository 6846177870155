import React from 'react';

const Plan = () => {
    const data = {
        investorShares: [
            { type: 'Signals', unit: 'Amount/Day', standard: '5', plus: '7', premium: '10' },
            { type: 'Technical Analysis', unit: 'Amount/Day', standard: '10 (7 languages)', plus: '15 (7 languages)', premium: '20 (7 languages)' },
            { type: 'Basic Analysis', unit: 'Amount/Day', standard: '10 (7 languages)', plus: '15 (7 languages)', premium: '20 (7 languages)' },
            { type: 'News', unit: 'Amount/Day', standard: '10 (7 languages)', plus: '15 (7 languages)', premium: '20 (7 languages)' },
            { type: 'Economic Calendar', unit: '-', standard: '✓', plus: '✓', premium: '✓' },
            { type: 'Notifications', unit: ['Push Notification', 'SMS', 'E-Mail'], standard: ['✓', '-', '-'], plus: ['✓', '-', '-'], premium: ['✓', '✓', '✓'] },
        ],
        educationsTrailers: [
            { type: 'Education Videos', unit: 'Amount', standard: '30 (English)', plus: '50 (English)', premium: '70 (English)' },
            { type: 'Education E-books', unit: 'Amount', standard: '30 (English)', plus: '50 (English)', premium: '70 (English)' },
            { type: 'Platform Educations', unit: ['Meta', 'Panda', 'C Trader'], standard: ['O', 'O', 'O'], plus: ['O', 'O', 'O'], premium: ['O', 'O', 'O'] },
            { type: 'Trailers', unit: 'Amount', standard: '3', plus: '5', premium: '7' },
        ],
        datas: [
            { type: 'Trading Volume', unit: '-', standard: '✓', plus: '✓', premium: '✓' },
            { type: 'Technical Summaries', unit: '-', standard: '✓', plus: '✓', premium: '✓' },
            { type: 'Markets', unit: '-', standard: '✓', plus: '✓', premium: '✓' },
        ],
        generalContents: [
            { type: 'Language Options', unit: 'Amount', standard: '7', plus: '10', premium: '10' },
            { type: 'Banner', unit: 'Piece', standard: '5 image', plus: '5 image', premium: '5 image' },
            { type: 'Desktop Interface', unit: '-', standard: '-', plus: '-', premium: '✓' },
            { type: 'Deposit&Withdraw System', unit: ['Bank Transfer', 'Credit Card', 'Crypto'], standard: ['✓', '✓', '✓'], plus: ['✓', '✓', '✓'], premium: ['✓', '✓', '✓'] },
        ],
        otherFeatures: [
            { type: 'FeedBack System', unit: '-', standard: '-', plus: '-', premium: '✓' },
            { type: 'IB System', unit: '-', standard: '-', plus: '-', premium: '✓' },
            { type: 'Special For You', unit: '-', standard: '-', plus: '-', premium: '✓' },
            { type: 'Gamification', unit: 'Amount', standard: '1', plus: '2', premium: '3' },
        ],
    };

    return (
        <div className='bg-[#EFF0F0] lg:px-20'>
            <div className='grid gap-3 mb-[44px]'>
                <h4 className='text-fitech-yellow text-center font-semibold leading-[32px]'>Pricing</h4>
                <h2 className='text-fitech-black text-[32px] font-semibold leading-[32px] text-center'>Choose your plan</h2>
                <p className='text-fitech-grey leading-[24px] text-center'>Determine which plan is most suitable for you for AFA and get in touch with us</p>
            </div>
            <div className="overflow-x-auto p-3">
                <table className='w-full mx-auto text-center table-auto'>
                    <thead className='mb-3'>
                        <tr>
                            <th className='lg:text-xl text-xs leading-[24px] lg:py-3 lg:px-4 lg:w-[220px] md:w-auto text-start'>INVESTOR SHARES</th>
                            <th className='font-normal leading-[20px] lg:py-3 lg:px-4 lg:w-[220px] md:w-auto text-start'>Unit</th>
                            <th className='lg:text-2xl text-xs leading-[24px] lg:py-3 lg:px-4 lg:w-[220px] md:w-auto text-start'>STANDARD</th>
                            <th className='lg:text-2xl text-xs leading-[24px] lg:py-3 lg:px-4 lg:w-[220px] md:w-auto text-start'>PLUS</th>
                            <th className='lg:text-2xl text-xs leading-[24px] lg:py-3 lg:px-4  lg:w-[220px] md:w-auto text-start'>PREMIUM</th>
                        </tr>
                    </thead>
                    <tbody className='p-4 table-row-group'>
                        {Object.entries(data).map(([category, items], categoryIndex) => (
                            <React.Fragment key={category}>
                                {categoryIndex !== 0 && <tr className="my-8"><td colSpan="5"></td></tr>}

                                <tr>
                                    <th colSpan="5" className='py-3 lg:px-4 lg:text-xl text-xs font-semibold text-start'>{category.toUpperCase()}</th>
                                </tr>

                                {items.map((item, index) => (
                                    <tr key={index} className={index % 2 === 0 ? 'bg-[#F9F9FB]' : ''}>
                                        <td className='py-3 lg:px-4 lg:w-[220px] md:w-auto text-start lg:text-xl text-xs'>{item.type}</td>
                                        <td className='py-3 lg:px-4 lg:w-[220px] md:w-auto text-start lg:text-xl text-xs'>
                                            {Array.isArray(item.unit) ? (
                                                item.unit.map((unit, index) => <div key={index}>{unit}</div>)
                                            ) : (
                                                item.unit
                                            )}
                                        </td>
                                        <td className='py-3 lg:px-4 lg:w-[220px] md:w-auto text-start lg:text-xl text-xs'>
                                            {Array.isArray(item.standard) ? (
                                                item.standard.map((standard, index) => <div key={index}>{standard}</div>)
                                            ) : (
                                                item.standard
                                            )}
                                        </td>
                                        <td className='py-3 lg:px-4 lg:w-[220px] md:w-auto text-start lg:text-xl text-xs'>
                                            {Array.isArray(item.plus) ? (
                                                item.plus.map((plus, index) => <div key={index}>{plus}</div>)
                                            ) : (
                                                item.plus
                                            )}
                                        </td>
                                        <td className='py-3 lg:px-4 lg:w-[220px] md:w-auto text-start lg:text-xl text-xs'>
                                            {Array.isArray(item.premium) ? (
                                                item.premium.map((premium, index) => <div key={index}>{premium}</div>)
                                            ) : (
                                                item.premium
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Plan;
