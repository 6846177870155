import React from 'react'
import BannerComponent from '../components/BannerComponent'
import Catalogue from '../components/Catalogue'
import OtherComponents from '../components/OtherComponents'

import Image from '../images/banner/desktop-interface.png'

const BannerContent = {
  title:"Desktop Interface",
  desc:"Yatırımcılar, Gelişmiş Finans Uygulaması’nın sağladığı tüm avantajları dizüstü ve masaüstü bilgisayarlarda da deneyimleyebilirler. Mobil uygulamada bulunan tüm componentler responsive olarak web tarayıcılar üzerinden kullanılabilir ve yatırımcılara her an her yerden ulaşım imkanı sağlamış olur.",
  image:Image,
}

const DesktopInterface = () => {

  return (

    <>
      <BannerComponent data={BannerContent}/>
      <OtherComponents/>
      <Catalogue/>
    </>

  )

}

export default DesktopInterface