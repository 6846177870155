import React from 'react'
import BannerComponent from '../components/BannerComponent'
import Catalogue from '../components/Catalogue'
import OtherComponents from '../components/OtherComponents'

import Image from '../images/banner/technical-basic.png'

const BannerContent = {
  title:"Basic Analysis",
  desc:"Küresel piyasalara dair; Forex’ ten Endekslere, Emtia’ dan Hisse Senetlerine, ETF’ lerden Kripto Paralara ve Paritelere kadar 10 adet detaylı temel analiz 7 farklı dilde paylaşılmaktadır. Teknik analizlerde olduğu gibi temel analiz paylaşımları da bildirim şeklinde yatırımcıların ekranlarında anlık olarak görüntülenebilmektedir.<br/><br/> Temel analizde incelenen başlıca göstergeler;<br/> • merkez bankası açıklamaları<br/>• şirket bilanço ve ticari göstergeleri• ticari savaş ve anlaşmalar<br/>• küresel enflasyon<br/> gibi dünya genelindeki finansal varlıkları etkileyen göstergelerdir.",
  image:Image,
}

const TechnicalBasicAnalysis = () => {

  return (

    <>
      <BannerComponent data={BannerContent}/>
      <OtherComponents/>
      <Catalogue/>
    </>

  )

}

export default TechnicalBasicAnalysis