import React from 'react'
import BannerComponent from '../components/BannerComponent'
import Catalogue from '../components/Catalogue'
import OtherComponents from '../components/OtherComponents'

import Image from '../images/banner/language-options.png'

const BannerContent = {
  title:"Language Options",
  desc:"In the application, 7 different language options are provided: English, Russian, German, Spanish, French, Chinese, and Arabic. Additional languages can be integrated into the application upon request.<br/><br/> Along with the language selection option:<br/>• basic and technical analyses,<br/>• news,<br/>• economic calendar,<br/>• campaigns,<br/>• announcements are integrated according to the selected language. <br/><br/>Educational content is standardly provided in English, and if a different language option(s) is requested, additional charges may apply.",
  image:Image,
}

const LanguageOptions = () => {

  return (

    <>
      <BannerComponent data={BannerContent}/>
      <OtherComponents/>
      <Catalogue/>
    </>

  )

}

export default LanguageOptions