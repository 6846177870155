import React from 'react'
import BannerComponent from '../components/BannerComponent'
import Catalogue from '../components/Catalogue'
import OtherComponents from '../components/OtherComponents'

import Image from '../images/banner/deposit-withdraw.png'

const BannerContent = {
  title:"Deposit & Withdraw",
  desc:"Investors can securely and quickly perform deposit and withdrawal transactions through the application. With the integrated payment system, deposit and withdrawal transactions can be made;<br/><br/>• Bank Transfer <br/>• Credit Card<br/> • With cryptocurrency options.<br/><br/> Investors can create deposit/withdrawal requests instantly without the need for any institution employee thanks to this automated system.",
  image:Image,
}

const DepositWithdraw = () => {

  return (

    <>
      <BannerComponent data={BannerContent}/>
      <OtherComponents/>
      <Catalogue/>
    </>

  )

}

export default DepositWithdraw