import React from 'react'

import Technical from '../images/technical-fundamental.png'
import Trading from '../images/trading-volume.png'
import TechnicalSummary from '../images/technical-summaries.png'
import Deposit from '../images/deposit-withdraw.png'

const components = [
    {
        image: Technical,
        title: "Technical&Fundamental Analysis",
        desc: "Daily minimum 10+ fundamental analysis and detailed technical analysis on global markets"
    },
    {
        image: Trading,
        title: "Trading Volume",
        desc: "The ratio of Buy&Sell tradings occurring in the institution and indicators of product trading intensity"
    },
    {
        image: TechnicalSummary,
        title: "Technical Summaries",
        desc: "Summaries supported by technical indicators for financial assets"
    },
    {
        image: Deposit,
        title: "Deposit&Withdraw",
        desc: "A secure and fast deposit and withdrawal system with bank transfer, credit card, and cryptocurrency options"
    },
]

const OtherComponents = () => {
  return (
    <>
    <div className='py-10 lg:px-20'>
        <p className='text-fitech-yellow font-bold lg:text-left text-center'>Services</p>
        <p className='text-3xl font-bold pb-2 lg:text-left text-center'>Other Components</p>
        <hr className='border-[1px] max-w-[280px] border-fitech-yellow lg:mx-0 mx-auto' />

        <div className='grid lg:grid-cols-4 sm:grid-cols-2 gap-8 py-8'>
            {components.map((c)=> {
                return(
                    <div className='max-w-[256px] mx-auto text-center'>
                        <img src={c.image} alt="" />
                        <p className='font-bold py-2'>{c.title}</p>
                        <p>{c.desc}</p>
                    </div>
                )
            })}
        </div>
    </div>
    </>
  )
}

export default OtherComponents