import React from 'react';
import EducaitonBanner from '../images/banner/education_banner.png';
import Ebook from '../images/banner/e-book.png';
import Platform from '../images/banner/platform.png';
import OtherComponents from '../components/OtherComponents';
import Catalogue from '../components/Catalogue';

import Education1 from '../images/education1.png'
import Education2 from '../images/education2.png'
import Education3 from '../images/education3.png'
import Education4 from '../images/education4.png'

const Education = () => {
    const educationData = [
        'What is Balance?',
        'What is Equity?',
        'What is Free Margin?',
        'What are Major Pairs?',
        'Base and Quote Currencies',
        'What is Bid?',
        'What is Ask?',
        'What are Exotics?',
        'What is Forex?',
        'What is a Lot?',
        'What is Leverage?',
        'What is Margin?',
        'What is Margin Level?',
        'What is Pip Value?',
        'Trade Strategy',
        'What is Spread?',
        'What is Stop Out?',
        'What is Take Profit?',
        'What is Stop Loss?',
        'What is Swap?',
        'What is Cross Rate?',
        'Sell Stop Limit',
        'Buy Stop Limit',
        'TP (Take Profit)',
        'SL (Stop Loss)',
        'Buy Limit and Sell Limit',
        'Buying and Selling Practices with Prices',
        'Long and Short Positions',
        'Stops Loss'
    ];

    const ebook = [
        'Definition and Content of Forex Markets',
        'Pending Orders',
        'Types of Trends',
        'Example Buy/Sell Strategies Within Trend Channels',
        'What is RSI?',
        'What are Bollinger Bands?',
        'What is MACD?',
        'What is Moving Average?',
        'What is Stochastic?',
        'What is Alligator?',
        'What is Gator?',
        'What are Fractals?',
        'Combined Use of EMA + RSI + MACD Indicators'
    ]

    const platforms = [
        'Platform Interface',
        'Basic Features',
        'Trading Accounts',
        'Membership Procedures',
        'Identity Verification',
        'Withdrawal and Deposit Transactions',
        'Order Types',
        'Reading Charts and Formations',
        'Technical Indicators',
        'Oscillators'
    ]
    return (
        <>
            <div className='grid lg:grid-cols-2 items-center mb-[75px] p-3 lg:px-20'>
                <div className='lg:w-[542px]'>
                    <h3 className='text-[32px] leading-[40px] font-bold mb-[19px]'>
                        Education Videos
                    </h3>
                    <p className='mb-5'>
                    More than 30 educational videos on the Forex market are available, ranging from basic to advanced levels.
                    </p>
                    <ul className="flex flex-wrap list-disc pl-5 mb-2">
                        {educationData.map((title, index) => (
                            <li key={index} className="w-1/2">
                                <p className='leading-[24px]'>{title}</p>
                            </li>
                        ))}
                    </ul>
                    <p>and many more educational videos are available within the application.</p>
                </div>
                <div>
                    <img src={EducaitonBanner} alt="" />
                </div>
            </div>
            <div className='grid lg:grid-cols-2 items-center mb-[75px] p-3'>
                <div>
                    <img src={Ebook} alt="" />
                </div>
                <div className='lg:w-[542px]'>
                    <h3 className='text-[32px] leading-[40px] font-bold mb-[19px]'>
                        E-books
                    </h3>
                    <p className='mb-5'>More than 30 e-books on global markets are available, ranging from basic to advanced levels.</p>
                    <ul className="list-disc pl-5 mb-2">
                        {ebook.map((title, index) => (
                            <li key={index} className="mb-2">
                                <p className='leading-[24px]'>{title}</p>
                            </li>
                        ))}
                    </ul>
                    <p>and many more e-books are available within the application.</p>
                </div>
            </div>
            <div className='grid lg:grid-cols-2 items-center mb-[75px] p-3 lg:px-20'>
                <div className='lg:w-[542px]'>
                    <h3 className='text-[32px] leading-[40px] font-bold mb-[19px]'>
                        Platforms
                    </h3>
                    <p className='mb-5'>
                    Educational videos on transactions that can be performed on the preferred platform (MetaTrader, C Trader, Panda) can be included in the application.
                    </p>
                    <ul className=" list-disc pl-5 mb-2">
                        {platforms.map((title, index) => (
                            <li key={index}>
                                <p className='leading-[24px]'>{title}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <img src={Platform} alt="" />
                </div>
            </div>

                                
            <div className='xl:flex hidden xl:h-96'>
                <div className='absolute left-48 z-10'>
                    <img src={Education1} alt="" />
                </div>
                <div className='absolute left-[460px] pt-28 z-0'>
                    <img src={Education2} alt="" />
                </div>
                <div className='absolute left-[720px] z-10'>
                    <img src={Education3} alt="" />
                </div>
                <div className='absolute left-[1000px] pt-28 z-20'>
                    <img src={Education4} alt="" />
                </div>
            </div>

            <div className='xl:hidden block'>
                <img className='mx-auto' src={Education1} alt="" />
                <img className='mx-auto' src={Education2} alt="" />
                <img className='mx-auto' src={Education3} alt="" />
                <img className='mx-auto' src={Education4} alt="" />
            </div>

            <OtherComponents />
            <Catalogue />
        </>
    );
}

export default Education;
