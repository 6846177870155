import React from 'react'

import MailIco from '../images/icons/mail.svg'
import PhoneIco from '../images/icons/phone.svg'
import Fitech from '../images/icons/fitechPro.svg'
import Rot3 from '../images/icons/developedRot3.svg'

const Footer = () => {
  return (
    <>
    <div className='bg-[#FAFAFA] p-8' id='footer'>

    <hr className='border-2 max-w-[1800px] mx-auto' />

      <div className='py-8'>
        <p className='font-bold text-2xl text-center pb-2'>CONTACT US</p>
        <hr className='border-[1px] max-w-[300px] mx-auto border-fitech-yellow' />
        <div className='grid lg:grid-cols-2 gap-2 max-w-[500px] mx-auto pt-3 text-md lg:text-left text-center'>
          <div>
            <p>Çiftehavuzlar Mh. Eski Londra Asfaltı Cd. Kuluçka Merkezi A1 Blok No:151 1/C Esenler/İstanbul</p>
          </div>
          <div className='flex flex-col justify-center gap-2'>
            <div className='lg:flex'>
              <img className='lg:mx-0 mx-auto' src={MailIco} alt="" />
              <p className='pl-2'>info@rot3.com</p>
            </div>
            <div className='lg:flex'>
              <img className='lg:mx-0 mx-auto' src={PhoneIco} alt="" />
              <p className='pl-2'>+44 7307 289360</p>
            </div>
          </div>
        </div>
      </div>

    <hr className='border-2 max-w-[1800px] mx-auto'  />
    
      <div className='flex items-center justify-center gap-4 pt-8'>
        <div>
          <img src={Fitech} alt="" />
        </div>
        <div>
          <p className='text-gray-400'>|</p>
        </div>
        <div>
          <img src={Rot3} alt="" />
        </div>
      </div>

    </div>
    </>
  )
}

export default Footer