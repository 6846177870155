import React from 'react'

import CatalogueImage from '../images/catalogue.png'

const Catalogue = () => {
  return (
    <>
      <div className='grid lg:grid-cols-2 items-center py-8'>
        <div>
          <img className='mx-auto' src={CatalogueImage} alt="" />
        </div>
        <div className='text-center'>
          <p className='font-bold text-fitech-yellow'>Catalogue</p>
          <p className='text-3xl font-bold py-2'>Explore all our services</p>
          <p className='text-[#5C6670] pb-2 max-w-[500px] mx-auto mb-2'>Download our catalog to see all the advantages provided by the Advanced Finance Application.</p>
            <a className='border-[1px] border-gray-400 p-2 font-bold' href="/fitech.pdf" download >Download</a>
        </div>
      </div>
    </>
  )
}

export default Catalogue