import React from 'react'
import BannerComponent from '../components/BannerComponent'
import Catalogue from '../components/Catalogue'
import OtherComponents from '../components/OtherComponents'

import Image from '../images/gamification_banner.png'

const BannerContent = {
  title:"Gamification",
  desc:"Integrating Gamification into the application leads to increased engagement and user retention. The play/win and draw modules added to the application through Gamification increase the time users spend on the application and also enhance their potential for investment.<br/><br/> Some advantages of Gamification include:<br/>• Increasing interaction<br/>• Ensuring customer satisfaction<br/>• Encouraging investment<br/>• Increasing the level of customer activity<br/>•  Attracting new investors<br/>• Increasing the application usage rate<br/>• Increasing the institution's volume during low investment periods.",
  image:Image,
}

const Gamification = () => {

  return (

    <>
      <BannerComponent data={BannerContent}/>
      <OtherComponents/>
      <Catalogue/>
    </>

  )

}

export default Gamification