import React from 'react'
import BannerComponent from '../components/BannerComponent'
import Catalogue from '../components/Catalogue'
import OtherComponents from '../components/OtherComponents'

import Image from '../images/banner/special-for-you.png'

const BannerContent = {
  title:"Special for You",
  desc:"The area where the privileges offered to investors are presented individually based on each investor's own campaign package is a space tailored specifically for the investor. This allows for the creation and presentation of personalized campaign content for each individual, as well as the ability to track them.<br/><br/> Moreover, within this personalized area, individuals can view information about the campaign name, campaign date, and the status of the campaign. Investors can select the privileges they wish to request from the campaign offerings and submit them for system approval, thereby creating a customized system for the investor.",
  image:Image,
}

const SpecialForYou = () => {

  return (

    <>
      <BannerComponent data={BannerContent}/>
      <OtherComponents/>
      <Catalogue/>
    </>

  )

}

export default SpecialForYou