import './App.css';
import {
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";

import Layout from './components/Layout';
import Homepage from './pages/Homepage';
import PageNotFound from './pages/PageNotFound';
import Signals from './pages/Signals';
import TechnicalAnalysis from './pages/TechnicalAnalysis';
import TechnicalBasicAnalysis from './pages/TechnicalBasicAnalysis';
import TradingVolume from './pages/TradingVolume';
import TechnicalSum from './pages/TechnicalSum';
import DepositWithdraw from './pages/DepositWithdraw';
import NewsEconomicCalendar from './pages/NewsEconomicCalendar';
import LanguageOptions from './pages/LanguageOptions';
import Markets from './pages/Markets';
import SpecialForYou from './pages/SpecialForYou';
import Gamification from './pages/Gamification';
import Notification from './pages/Notification';
import Banner from './pages/Banner';
import DesktopInterface from './pages/DesktopInterface';
import Education from './pages/Education';


function App() {
  return (
    <>
       <BrowserRouter>
        <Routes>
            <Route path="/" element={<Layout />}>

                <Route index element={<Homepage />} />
                <Route path="signals" element={<Signals />}/>
                <Route path="technical-analysis" element={<TechnicalAnalysis />}/>
                <Route path="technical-basic-analysis" element={<TechnicalBasicAnalysis />}/>
                <Route path="trading-volume" element={<TradingVolume />}/>
                <Route path="technical-sum" element={<TechnicalSum />}/>
                <Route path="deposit-withdraw" element={<DepositWithdraw />}/>
                <Route path="news-economic-calendar" element={<NewsEconomicCalendar />}/>
                <Route path="language-options" element={<LanguageOptions />}/>
                <Route path="markets" element={<Markets />}/>
                <Route path="special-for-you" element={<SpecialForYou />}/>
                <Route path="gamification" element={<Gamification />}/>
                <Route path="notifications" element={<Notification />}/>
                <Route path="banner" element={<Banner />}/>
                <Route path="desktop-interface" element={<DesktopInterface />}/>
                <Route path="education" element={<Education />}/>
            </Route>
            <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
